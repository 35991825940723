import { GaslessType } from "../../../constants/Types";
import Ad from "./Ad";

const Gasless = ({
  isEoa,
  isGaslessDisabled,
  // isGaslessActive,
  // toggleGaslessSwitch,
  adModal,
  handleAdComplete,
  rewardInfo,
}: GaslessType) => {
  /** Gasless transactions are available without any condition so toggle is not required as of now.
   * If the situation changes later then use this commented function in place of the other one.

  const goGaslessButton = () => (
    <>
      <p
        className={` font-sans font-semibold tracking-wide ${
          isGaslessDisabled ? ` text-gray-500 ` : ""
        }`}
      >
        Go Gasless
      </p>

      <ToggleSwitch
        width={40}
        value={isGaslessActive}
        isDisable={isGaslessDisabled}
        onChange={toggleGaslessSwitch}
      />
    </>
  );

  */

  const goGaslessButton = (text: string) => (
    <>
      <p
        className={` font-sans font-semibold tracking-wide ${
          isGaslessDisabled ? ` text-gray-500 ` : ""
        }`}
      >
        {text}
      </p>
    </>
  );

  return (
    <>
      <div
        className={`flex items-center justify-between w-[85%] pr-1 pb-2 text-white text-base  `}
      >
        {/* {isEoa && isGaslessDisabled ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="w-[100%] flex justify-between">
                {goGaslessButton()}
              </TooltipTrigger>
              <TooltipContent className="bg-secondary-bg text-white border-black ">
                <p className="whitespace-nowrap">
                  {isEoa
                    ? "Gasless transaction not available for EOA"
                    : "Gasless transaction not available as this moment."}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          goGaslessButton()
        )} */}

        {isEoa || isGaslessDisabled
          ? goGaslessButton(
              isEoa
                ? "Gasless transactions are not available for General accounts"
                : "Gasless transaction is not available as this moment.",
            )
          : goGaslessButton("Proceed with your gasless transaction.")}
      </div>
      <Ad
        adModal={adModal}
        handleAdComplete={handleAdComplete}
        rewardInfo={rewardInfo}
      />
    </>
  );
};

export default Gasless;
